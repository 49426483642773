import { Avatar } from 'src/design-system'
import * as React from 'react'
import { Org } from 'store/modules/orgs'
import * as MegaModal from '../../mega-modal'

export const OrgInfoLine: React.VFC<{ org: Org }> = (props) => {
  const { org } = props

  return (
    <MegaModal.InfoLine
      label="Created by"
      value={
        <div className="flex gap-2 items-center text-gray-900">
          <Avatar
            alt={`Avatar for ${org.name}`}
            fallbackText={org.name[0]}
            size="xs"
            src={org.avatarUrl}
          />
          {org.name}
        </div>
      }
    />
  )
}
