import { SkillLevel } from 'store/modules/skill-levels'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'

export class SkillLevelRequirementCardVm {
  constructor(
    private skillLevel: SkillLevel,
    private skillVariant: SkillVariant
  ) {}

  async createOutcome(content: string, source: string) {
    return await store.outcomes.create(
      {
        content,
        skillLevel: this.skillLevel.id,
      },
      { source }
    )
  }

  fetchOutcomes() {
    store.outcomes.fetchAll(
      {
        filter: {
          level: this.skillLevel.level,
          skill_level_id: this.skillLevel.id,
        },
        page: { size: 100 },
      },
      { bypassCache: true }
    )
  }

  fetchChanges(size: number) {
    store.changes.fetchForResource(this.skillVariant.id, 'SkillVariant', size)
  }
}
