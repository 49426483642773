import { Tag, Tooltip } from 'src/design-system'
import * as React from 'react'
import { Team } from 'store/modules/teams'
import * as MegaModal from '../../mega-modal'

export const UsedByInfoLine: React.VFC<{ teams: Team[] }> = (props) => {
  const { teams } = props

  const firstTeam = teams[0]

  const tooltipContent = teams
    .slice(1)
    .map((team) => <div key={team.id}>{team.name}</div>)

  return (
    <MegaModal.InfoLine
      label="Used by"
      value={
        teams.length === 1 ? (
          firstTeam.name
        ) : (
          <div className="flex items-center text-gray-900">
            {firstTeam.name}
            <span className="mx-2">·</span>
            <Tooltip content={tooltipContent}>
              <Tag variant="paper">
                <p className="mb-0 text-xs">+{teams.length - 1}</p>
              </Tag>
            </Tooltip>
          </div>
        )
      }
    />
  )
}
