import {
  Banner,
  BannerProps,
  PropsWithoutChildren,
  useTimedContent,
} from 'src/design-system'
import { Info } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import pluralize from 'pluralize'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'src/store'

export type MultipleFrameworksBannerProps = {
  frameworkCount: number
  skillVariant: SkillVariant
  onCreateVariantClick: () => void
} & PropsWithoutChildren<Omit<BannerProps, 'onDismiss' | 'variant'>>

export const MultipleFrameworksBanner = observer(
  (props: MultipleFrameworksBannerProps) => {
    const { onCreateVariantClick, frameworkCount, skillVariant, ...restProps } =
      props

    const { hideFor, show } = useTimedContent(
      `skillModalMultipleFrameworksBanner-${skillVariant.id}`
    )

    if (frameworkCount < 2 || !show) return null

    const positionCount = skillVariant.requirements.length

    const positionText =
      positionCount > 0
        ? `${pluralize('positions', positionCount, true)} in `
        : ''

    const onDismiss = () => {
      hideFor({ hours: 24 })
      trackEvent('$track_skill_modal_multiple_frameworks_banner_dismissed')
    }

    const onCreateVariantClickAction = () => {
      onCreateVariantClick()
      trackEvent(
        '$track_skill_modal_multiple_frameworks_banner_create_variant_clicked'
      )
    }

    return (
      <Banner
        className="absolute bottom-6 flex items-center left-0 mx-auto my-0 right-0 rounded w-max z-30"
        onDismiss={onDismiss}
        variant="warning"
        {...restProps}
      >
        <div className="flex gap-3 items-center mr-3">
          <Info className="h-4 text-yellow-700 w-4" weight="bold" />
          <p className="mb-0 text-sm text-yellow-800">
            Editing this skill will affect {positionText}
            {frameworkCount} teams.{' '}
            {store.featureGates.canAccess('skill_variants') && (
              <>
                <button
                  onMouseDown={onCreateVariantClickAction}
                  className="underline text-theme-40"
                >
                  Create a variant instead
                </button>
                <button
                  className="text-yellow-800 underline ml-2"
                  onMouseDown={() => {
                    window.open(
                      'https://help.progressionapp.com/en/articles/8065002-how-to-variant-skills',
                      '_blank'
                    )
                  }}
                >
                  Learn more
                </button>
              </>
            )}
          </p>
        </div>
      </Banner>
    )
  }
)
