import { Button, Dropdown, useModalContext } from 'src/design-system'
import * as React from 'react'
import { Skill } from 'store/modules/skills'
import { SkillVariant } from 'store/modules/skill-variants'
import { SKILL_MODAL_ID } from '../utils'
import { Framework } from 'store/modules/frameworks'
import { store } from 'store/index'
import cn from 'classnames'
import { SkillVariantDialog } from 'components/skill-variant-dialog'
import { can } from 'app/packs/src/policies'

export const SkillVariantToggle: React.VFC<{
  skill: Skill
  skillVariant?: SkillVariant
  framework?: Framework
}> = (props) => {
  const { skill, skillVariant, framework } = props

  const [open, setOpen] = React.useState(false)
  const [hoverId, setHoverId] = React.useState<string | undefined>(undefined)
  const { openModal } = useModalContext()

  const onClickVariant = (skillVariantId: string) => {
    openModal(SKILL_MODAL_ID, {
      frameworkId: framework?.id,
      skillId: skill.id,
      skillVariantId: skillVariantId,
    })
    setOpen(false)
  }

  const frameworkVariant =
    framework &&
    store.frameworksSkills.forFrameworkAndSkill(framework?.id, skill.id)
      ?.skillVariant

  const skillVariants = store.skillVariants.sortedForSkill(skill.id)

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <Dropdown.Trigger className="capitalize">
        {skillVariant?.name || skill.defaultVariant?.name}
      </Dropdown.Trigger>
      <Dropdown.Content>
        {skillVariants.map((variant) => (
          <Dropdown.Button
            type="button"
            key={variant.name}
            className={cn(
              'min-w-[180px] bg-transparent focus:!bg-gray-50 hover:!bg-gray-50 hover:!text-gray-900 flex flex-row gap-x-2 px-4 py-3 items-center justify-between cursor-pointer text-gray-900',
              {
                '!text-gray-600': variant.id !== skillVariant?.id,
                '!bg-gray-50': variant.id === skillVariant?.id,
              }
            )}
            onClick={() => onClickVariant(variant.id)}
            onMouseOver={() => setHoverId(variant.id)}
            onMouseOut={() => setHoverId(undefined)}
          >
            {variant.name}
            <span
              className={cn(
                'text-green-700',
                hoverId === variant.id &&
                  variant.id !== skillVariant?.id &&
                  variant.id !== frameworkVariant?.id &&
                  framework &&
                  frameworkVariant
                  ? 'block'
                  : 'hidden'
              )}
            >
              Preview
            </span>
          </Dropdown.Button>
        ))}
        {!!store.currentUser && can(store.currentUser, skill).edit && (
          <SkillVariantDialog frameworkId={framework?.id} skillId={skill.id}>
            <Button className="flex px-4 py-3 min-w-full justify-start focus:shadow-none bg-transparent hover:!bg-gray-50 text-green-700 rounded-none">
              Create new variant
            </Button>
          </SkillVariantDialog>
        )}
      </Dropdown.Content>
    </Dropdown.Root>
  )
}
