import { Link, useModalContext } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { EditorContext } from 'components/atoms/editor'
import { InfoPane } from './info-pane'
import { LoadingContent } from 'components/loading-content'
import { ModifiableSkillAttributes } from 'store/modules/skills'
import { MultipleFrameworksBanner } from './multiple-frameworks-banner'
import { SkillLevelRequirementsSection } from './skill-level-requirements-section'
import { SkillModalContentVm } from './skill-modal-content-vm'
import { SkillModalHeader } from './header'
import { SkillModalNav } from './nav'
import { SkillModalProps } from './utils'
import { SkillVariantDialog } from 'components/skill-variant-dialog'
import { SkillVariantPreviewBanner } from './skill-variant-preview-banner'
import { store } from 'store/index'
import { successToast } from '../../utils/success-toast'
import { trackEvent } from '../../services/event-tracker'
import { useStore } from 'store/context'
import * as MegaModal from '../mega-modal'

export type SkillModalContentProps = SkillModalProps & {
  showCloseButton?: boolean
}

export const SkillModalContent = observer<SkillModalContentProps>((props) => {
  const {
    frameworkId,
    showCloseButton,
    showControls,
    showMakeACopy,
    showRemoveFromTeam,
    skillId,
    skillVariantId,
    source,
  } = props

  const { changes, skills, frameworks, frameworksSkills, skillVariants } =
    useStore()

  const [errors, setErrors] = React.useState<string[]>([])

  const skillDescriptionEditor = React.useRef<EditorContext>(null)

  const [formState, setFormState] = React.useState<
    Partial<ModifiableSkillAttributes>
  >({})

  const [showBanner, setShowBanner] = React.useState(false)

  const [openSkillVariantDialog, setOpenSkillVariantDialog] =
    React.useState(false)

  const skill = skills.byId(skillId)
  const team = frameworks.byId(frameworkId)?.team

  const skillVariant = React.useMemo(() => {
    return (
      skillVariants.byId(skillVariantId) ||
      (frameworkId &&
        frameworksSkills.forFrameworkAndSkill(frameworkId, skillId)
          ?.skillVariant) ||
      skill?.defaultVariant
    )
  }, [
    frameworkId,
    frameworksSkills,
    skill?.defaultVariant,
    skillId,
    skillVariantId,
    skillVariants,
  ])

  React.useEffect(() => {
    if (skillVariant) {
      store.skillVariants.fetchOne(skillVariant.id, {
        include: [
          'requirements',
          'skill',
          'skill.content_license_grant',
          'skill.frameworks_skills',
          'skill.frameworks_skills.category',
          'skill.frameworks_skills.framework',
          'skill.frameworks_skills.framework.team',
          'skill.frameworks_skills.framework.team.org',
          'skill_levels',
          'skill_levels.outcomes',
        ],
      })
    } else {
      store.skills.fetchOne(skillId, {
        include: ['skill_variants'],
      })
    }
  }, [skillId, skillVariant])

  React.useEffect(() => {
    if (skillVariant) {
      store.comments.fetchAll({
        filter: {
          commentable_id: skillVariant.id,
          commentable_type: 'SkillVariant',
        },
      })
    }
  }, [skillVariant])

  const clearFieldErrors = React.useCallback(
    (field: string) => {
      setErrors(errors.filter((error) => error !== field))
    },
    [errors]
  )

  const onChange = React.useCallback(
    <Field extends keyof ModifiableSkillAttributes>(
      field: Field,
      value: ModifiableSkillAttributes[Field] | null
    ) => {
      setFormState((formState) => ({ ...formState, [field]: value }))
    },
    []
  )

  const onBlur = React.useCallback(
    async (field: keyof typeof formState, resetOnFailure?: boolean) => {
      setShowBanner(false)

      if (!skill) return
      if (formState[field] === skill[field]) return

      const result = await skills.update(skill.id, formState)

      if (result.success) {
        clearFieldErrors(field)
        successToast('Skill updated')

        document.dispatchEvent(new Event('refreshOrgSkills'))
        document.dispatchEvent(new Event('refreshSkillsDrawerSkills'))
        document.dispatchEvent(new Event('refreshTeamSkillsPage'))

        if (skillVariant) {
          changes.fetchForResource(skillVariant.id, 'SkillVariant', 1)
        }
      } else {
        if (resetOnFailure) {
          clearFieldErrors(field)
          onChange(field, skill[field])
        } else {
          if (!errors.includes(field)) setErrors([...errors, field])
        }
      }
    },
    [
      changes,
      clearFieldErrors,
      errors,
      formState,
      onChange,
      skill,
      skills,
      skillVariant,
    ]
  )

  const vm = React.useMemo(() => {
    return new SkillModalContentVm(skillVariant)
  }, [skillVariant])

  const frameworkCount = skillVariant?.frameworkIds?.length || 0

  React.useEffect(() => {
    if (!skill) return

    setFormState({ description: skill.description, name: skill.name })

    trackEvent('$track_skill_viewed', {
      format: 'modal',
      skillId: skill.id,
      skillName: skill.name,
      source,
    })
  }, [skill, source])

  const skillNotFound = !skill || !skillVariant

  return (
    <LoadingContent className="pt-10" loading={skillNotFound && skills.loading}>
      {skillNotFound ? (
        <NotFound />
      ) : (
        <React.Fragment>
          <MegaModal.Nav>
            <SkillModalNav
              editable={vm.editable}
              showCloseButton={showCloseButton}
              showControls={showControls}
              showMakeACopy={showMakeACopy}
              showRemoveFromTeam={showRemoveFromTeam}
              skill={skill}
              skillVariant={skillVariant}
              team={team}
            />
          </MegaModal.Nav>
          <MegaModal.Body>
            <MegaModal.Header>
              <SkillModalHeader
                editable={vm.headerEditable}
                skillDescriptionEditor={skillDescriptionEditor}
                errors={errors}
                formState={formState}
                onBlur={onBlur}
                onChange={onChange}
                setShowBanner={setShowBanner}
                skill={skill}
                skillVariant={skillVariant}
              />
            </MegaModal.Header>
            <MegaModal.RightPane>
              <InfoPane
                editable={vm.editable}
                skillVariant={skillVariant}
                canAddToOrg={vm.showAddSkillToOrg}
              />
            </MegaModal.RightPane>
            <MegaModal.Content>
              <SkillLevelRequirementsSection
                deletable={vm.editable}
                editable={vm.editable}
                skillDescriptionEditor={skillDescriptionEditor}
                frameworkCount={frameworkCount}
                setShowBanner={setShowBanner}
                skill={skill}
                skillLevels={store.skillLevels.sortedForSkillVariant(
                  skillVariant.id
                )}
                skillVariant={skillVariant}
                team={team}
              />
            </MegaModal.Content>
          </MegaModal.Body>
          {showBanner && (
            <MultipleFrameworksBanner
              frameworkCount={frameworkCount}
              skillVariant={skillVariant}
              onCreateVariantClick={() => setOpenSkillVariantDialog(true)}
            />
          )}
          {!showBanner && frameworkId && (
            <SkillVariantPreviewBanner
              editable={vm.editable}
              frameworkId={frameworkId}
              skillVariant={skillVariant}
            />
          )}
          <SkillVariantDialog
            defaultOpen={openSkillVariantDialog}
            onOpenChange={setOpenSkillVariantDialog}
            skillId={skillVariant.skill.id}
            frameworkId={frameworkId}
            source={source}
          />
        </React.Fragment>
      )}
    </LoadingContent>
  )
})

const NotFound: React.VFC = () => {
  const org = store.currentUser?.org

  const { closeModal } = useModalContext()

  return (
    <div className="min-h-screen w-full h-full flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">Skill not found</h1>
        <p className="text-gray-600">We could not find this Skill.</p>
        {!!org && (
          <Link
            data-turbo-action="advance"
            data-turbo-frame="content"
            href={org.skillsPath}
            onClick={() => closeModal()}
            variant="brand"
          >
            Back to Skills
          </Link>
        )}
      </div>
    </div>
  )
}
