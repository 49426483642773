import { Button, ConfirmationDialog } from 'src/design-system'
import { CSS } from '@dnd-kit/utilities'
import { observer } from 'mobx-react-lite'
import { Trash } from '@phosphor-icons/react'
import { useSortable } from '@dnd-kit/sortable'
import * as React from 'react'
import cn from 'classnames'
import pluralize from 'pluralize'
import { DragHandle } from 'components/drag-handle'
import { Editor, EditorContext } from 'components/atoms/editor'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { ModifiableOutcomeAttributes, Outcome } from 'store/modules/outcomes'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'
import { successToast } from 'app/packs/src/utils/success-toast'
import styles from './styles.module.scss'

type SkillLevelOutcomeProps = {
  deletable?: boolean
  editable?: boolean
  frameworkCount: number
  outcome: Outcome
  setShowBanner: (showBanner: boolean) => void
  skillVariant: SkillVariant
  source: string
}

export const SkillLevelOutcome = observer((props: SkillLevelOutcomeProps) => {
  const {
    deletable,
    editable,
    frameworkCount,
    outcome,
    setShowBanner,
    skillVariant,
    source,
  } = props

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: outcome.id })

  const [errors, setErrors] = React.useState<string[]>([])

  const [formState, setFormState] = React.useState<
    Partial<ModifiableOutcomeAttributes>
  >({ content: outcome.content })

  const editorRef = React.useRef<EditorContext>(null)

  const clearFieldErrors = (field: string) => {
    setErrors(errors.filter((error) => error !== field))
  }

  const confirmationDialogBody =
    frameworkCount > 0
      ? `This will affect ${pluralize(
          'frameworks',
          frameworkCount,
          true
        )} and permanently remove this skill level example from Progression.`
      : 'This will permanently remove this skill level example from Progression.'

  const onBlur = async (field: keyof typeof formState) => {
    setShowBanner(false)

    if (formState[field] === outcome[field]) return

    const result = await store.outcomes.update(outcome.id, formState, {
      source,
    })

    if (result.success) {
      clearFieldErrors(field)
      successToast('Skill level example updated')
      store.changes.fetchForResource(skillVariant.id, 'SkillVariant', 1)
    } else {
      if (!errors.includes(field)) setErrors([...errors, field])
    }
  }

  const onChange = <Field extends keyof ModifiableOutcomeAttributes>(
    field: Field,
    value: ModifiableOutcomeAttributes[Field] | null
  ) => {
    setFormState((formState) => ({ ...formState, [field]: value }))
  }

  const onConfirm = React.useCallback(async () => {
    const result = await outcome.destroy({ source })
    if (result.success) {
      successToast('Skill level example deleted')
      store.changes.fetchForResource(skillVariant.id, 'SkillVariant', 1)
    } else {
      errorToast()
    }
  }, [outcome, skillVariant, source])

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <>
      {editable ? (
        <li
          className="relative group/outcome"
          ref={setNodeRef}
          style={style}
          {...attributes}
        >
          <ConfirmationDialog.Root
            body={confirmationDialogBody}
            contentClassName="m-auto sm:m-4"
            onConfirm={onConfirm}
            title="Are you sure?"
          >
            <>
              <div className={cn(styles.outcomeContainer)}>
                <Editor
                  className={cn(
                    'border-transparent hover:border-gray-200 focus-within:!border-theme-60 focus-within:!shadow-none w-full',
                    styles.nameEditor
                  )}
                  initialContent={outcome.content || ''}
                  invalid={errors.includes('content')}
                  key={`editor-outcome-${outcome.id}`}
                  name={`outcome-${outcome.id}-content`}
                  onBlur={() => onBlur('content')}
                  onChange={(value) => onChange('content', value)}
                  onFocus={() => setShowBanner(true)}
                  placeholder="E.g. You've repeatedly..."
                  ref={editorRef}
                  textSize="base"
                />
                {deletable && (
                  <ConfirmationDialog.Trigger className="bg-white border-0 btn-brand h-[fit-content] p-0 opacity-0 pointer-events-none group-hover/outcome:opacity-100 group-hover/outcome:pointer-events-auto transition-opacity">
                    <Trash className="h-4 text-gray-300 w-4" weight="bold" />
                    <span className="sr-only">Delete</span>
                  </ConfirmationDialog.Trigger>
                )}
              </div>
              <Button
                className={cn(
                  'absolute bg-white border-0 btn-brand -left-8 p-0 top-3.5',
                  styles.dragHandle
                )}
                {...listeners}
              >
                <DragHandle />
              </Button>
            </>
          </ConfirmationDialog.Root>
        </li>
      ) : (
        <li>
          <HtmlContent className={cn('p-2', styles.outcomeContent)}>
            {outcome.content}
          </HtmlContent>
        </li>
      )}
    </>
  )
})
