import { ConfirmationDialog, OverflowMenu } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import pluralize from 'pluralize'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { SkillLevel } from 'store/modules/skill-levels'
import { successToast } from 'app/packs/src/utils/success-toast'

type SkillLevelOverflowProps = {
  frameworkCount: number
  skillLevel: SkillLevel
  source: string
}

export const SkillLevelOverflow = observer((props: SkillLevelOverflowProps) => {
  const { frameworkCount, skillLevel, source } = props

  const onConfirm = React.useCallback(async () => {
    const result = await skillLevel.destroy({ source })

    if (result.success) {
      successToast('Skill level deleted')
      document.dispatchEvent(new Event('frameworkpage:updated'))
    } else {
      errorToast()
    }
  }, [skillLevel, source])

  const confirmationDialogBody =
    frameworkCount > 0
      ? `This will affect ${pluralize(
          'frameworks',
          frameworkCount,
          true
        )} and permanently remove this skill level from Progression.`
      : 'This will permanently remove this skill level from Progression.'

  return (
    <ConfirmationDialog.Root
      body={confirmationDialogBody}
      contentClassName="m-auto sm:m-4"
      onConfirm={onConfirm}
      title="Are you sure?"
    >
      <OverflowMenu.Root className="h-4 w-4" right>
        <ConfirmationDialog.Trigger asChild>
          <OverflowMenu.Item variant="destructive">Delete</OverflowMenu.Item>
        </ConfirmationDialog.Trigger>
      </OverflowMenu.Root>
    </ConfirmationDialog.Root>
  )
})
