import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Button } from 'src/design-system'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { observer } from 'mobx-react-lite'
import { Plus } from '@phosphor-icons/react'
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import * as React from 'react'
import cn from 'classnames'
import { compareArrays } from 'app/packs/src/utils/array-helpers'
import { Editor, EditorContext } from 'components/atoms/editor'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { ModifiableOutcomeAttributes, Outcome } from 'store/modules/outcomes'
import {
  ModifiableSkillLevelAttributes,
  SkillLevel,
} from 'store/modules/skill-levels'
import { AiGenerateButton } from 'components/ai/ai-generate-button'
import { NewSkillLevelOutcome } from './new-skill-level-outcome'
import { SkillLevelIndicator } from 'components/skill-level-indicator'
import { SkillLevelOutcome } from './skill-level-outcome'
import { SkillLevelOverflow } from './skill-level-overflow'
import { SkillLevelRequirementCardVm } from './skill-level-requirement-card-vm'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'
import { successToast } from 'app/packs/src/utils/success-toast'
import styles from './styles.module.scss'

type SkillLevelRequirementCardProps = {
  deletableLevel?: boolean
  deletableOutcome?: boolean
  editable?: boolean
  frameworkCount: number
  maxLevel: number
  outcomes: Outcome[]
  setShowBanner: (showBanner: boolean) => void
  skillLevel: SkillLevel
  skillVariant: SkillVariant
  source: string
}

export const SkillLevelRequirementCard = observer(
  (props: SkillLevelRequirementCardProps) => {
    const {
      deletableLevel,
      deletableOutcome,
      editable,
      frameworkCount,
      maxLevel,
      outcomes: externalOutcomes,
      setShowBanner,
      skillLevel,
      skillVariant,
      source,
    } = props

    const editorRef = React.useRef<EditorContext>(null)

    const [errors, setErrors] = React.useState<string[]>([])

    const [formState, setFormState] = React.useState<
      Partial<ModifiableSkillLevelAttributes>
    >({ name: skillLevel.name || '' })

    const [items, setItems] = React.useState(
      externalOutcomes.map((outcome) => outcome.id)
    )

    const [newOutcomesFormState, setNewOutcomesFormState] = React.useState<
      Record<string, Partial<ModifiableOutcomeAttributes>>
    >({})

    const outcomes = React.useMemo(
      () =>
        items.flatMap(
          (id) => externalOutcomes.find((outcome) => outcome.id === id) || []
        ),
      [externalOutcomes, items]
    )

    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    )

    const vm = React.useMemo(() => {
      return new SkillLevelRequirementCardVm(skillLevel, skillVariant)
    }, [skillLevel, skillVariant])

    const clearFieldErrors = (field: string) => {
      setErrors(errors.filter((error) => error !== field))
    }

    const clearNewOutcome = (key: string) => {
      const newState = { ...newOutcomesFormState }
      delete newState[key]
      setNewOutcomesFormState(newState)
    }

    const onBlur = async (field: keyof typeof formState) => {
      setShowBanner(false)

      if (formState[field] === skillLevel[field]) return

      const result = await store.skillLevels.update(skillLevel.id, formState, {
        source,
      })

      if (result.success) {
        clearFieldErrors(field)
        successToast('Skill level updated')
        vm.fetchChanges(1)
      } else {
        if (!errors.includes(field)) setErrors([...errors, field])
      }
    }

    const onChange = <Field extends keyof ModifiableSkillLevelAttributes>(
      field: Field,
      value: ModifiableSkillLevelAttributes[Field] | null
    ) => {
      setFormState((formState) => ({ ...formState, [field]: value }))
    }

    const onDragEnd = async (event: DragEndEvent) => {
      const { active, over } = event

      if (over && active.id !== over.id) {
        const currentItems = { ...items }
        const outcomeId = active.id.toString()
        const oldIndex = items.indexOf(outcomeId)
        const newIndex = items.indexOf(over.id.toString())

        setItems(arrayMove(items, oldIndex, newIndex))

        const result = await store.outcomes.update(
          outcomeId,
          { listPosition: newIndex + 1 },
          { source }
        )

        if (result.success) {
          vm.fetchChanges(currentItems.length)
          vm.fetchOutcomes()
        } else {
          errorToast()
          setItems(currentItems)
        }
      }
    }

    const onNewOutcomeBlur = async (key: string) => {
      setShowBanner(false)

      const content = newOutcomesFormState[key]['content']
      if (!content || content === '') return

      const result = await vm.createOutcome(content, source)
      if (result.success) {
        successToast('Skill level example created')
        clearNewOutcome(key)
        vm.fetchChanges(1)
      }
    }

    const onNewOutcomeClick = React.useCallback(() => {
      setNewOutcomesFormState((newOutcomesFormState) => {
        const keys = Object.keys(newOutcomesFormState)
        const newKey = keys.length > 0 ? parseInt(keys[keys.length - 1]) + 1 : 0

        return {
          ...newOutcomesFormState,
          [newKey.toString()]: { content: '' },
        }
      })
    }, [])

    const onNewOutcomeChange = <
      Field extends keyof ModifiableOutcomeAttributes
    >(
      field: Field,
      key: string,
      value: ModifiableOutcomeAttributes[Field] | null
    ) => {
      const newState = { ...newOutcomesFormState }
      newState[key] = { ...newState[key], [field]: value }
      setNewOutcomesFormState(newState)
    }

    React.useEffect(() => {
      if (outcomes.length !== 0) return
      if (Object.keys(newOutcomesFormState).length !== 0) return

      onNewOutcomeClick()
    }, [newOutcomesFormState, onNewOutcomeClick, outcomes])

    React.useEffect(() => {
      setItems((items) => {
        const externalIds = externalOutcomes.map((item) => item.id)
        return compareArrays(externalIds, items) ? items : externalIds
      })
    }, [externalOutcomes])

    return (
      <div className={cn('flex flex-col gap-4 py-10 group/level')}>
        <div className="flex gap-2 items-center justify-between">
          <h4 className="font-bold text-gray-900 text-2xl truncate">
            Level {skillLevel.level}
          </h4>
          <div className="flex gap-2 items-center">
            <SkillLevelIndicator level={skillLevel.level} maxLevel={maxLevel} />
            {deletableLevel && (
              <SkillLevelOverflow
                frameworkCount={frameworkCount}
                skillLevel={skillLevel}
                source={source}
              />
            )}
          </div>
        </div>
        {editable ? (
          <Editor
            className={cn(
              'border-transparent hover:border-gray-200 focus-within:!border-theme-60 focus-within:!shadow-none -mx-2',
              styles.nameEditor
            )}
            initialContent={skillLevel.name || ''}
            invalid={errors.includes('name')}
            key={`editor-skill-level-${skillLevel.id}`}
            name="name"
            onBlur={() => onBlur('name')}
            onChange={(value) => onChange('name', value)}
            onFocus={() => setShowBanner(true)}
            placeholder="E.g. Recognised as leading industry figure and uses baking to achieve organisational goals."
            ref={editorRef}
            textSize="base"
          />
        ) : (
          <HtmlContent className={cn(styles.name)}>
            {skillLevel.name}
          </HtmlContent>
        )}
        {(editable || (!editable && outcomes.length > 0)) && (
          <>
            <h5 className="font-bold text-base text-gray-900">Examples</h5>
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              onDragEnd={onDragEnd}
              sensors={sensors}
            >
              <ul className={cn(styles.list)}>
                <SortableContext
                  items={items}
                  strategy={verticalListSortingStrategy}
                >
                  {outcomes.map((outcome) => {
                    return (
                      <SkillLevelOutcome
                        deletable={deletableOutcome}
                        editable={editable}
                        frameworkCount={frameworkCount}
                        key={outcome.id}
                        outcome={outcome}
                        setShowBanner={setShowBanner}
                        skillVariant={skillVariant}
                        source={source}
                      />
                    )
                  })}
                </SortableContext>
                {editable &&
                  Object.keys(newOutcomesFormState).map((key) => (
                    <NewSkillLevelOutcome
                      index={key}
                      key={key}
                      onBlur={() => onNewOutcomeBlur(key)}
                      onChange={(field, value) =>
                        onNewOutcomeChange(field, key, value)
                      }
                      onDelete={() => clearNewOutcome(key)}
                      onFocus={() => setShowBanner(true)}
                    />
                  ))}
              </ul>
            </DndContext>
          </>
        )}
        {editable && (
          <div className="flex justify-start items-center gap-x-3 w-full">
            <Button
              as="button"
              className="border-gray-100 flex items-center px-3 py-1.5"
              onClick={onNewOutcomeClick}
              variant="outline"
            >
              <Plus className="h-4 text-theme-40 w-4" weight="bold" />
              <span className="text-gray-600 text-sm"> Add example</span>
            </Button>
            <AiGenerateButton
              className="opacity-0 pointer-events-none group-hover/level:opacity-100 group-hover/level:pointer-events-auto transition-opacity"
              source="inline-skill-example"
              inputs={{
                skillName: skillLevel.skill.name,
                skillDescription: skillLevel.skill.description,
                level: skillLevel.level,
                count: 1,
                existingExamples: outcomes.map((outcome) => outcome.content),
                skillLevelDescription: skillLevel.name,
              }}
              contentType="skillExample"
              onUse={async (output) => {
                const firstOutcome =
                  output.skillExamples?.output.extracted.at(0)
                const content = `<b>${firstOutcome?.skillExampleName}</b>: ${firstOutcome?.skillExampleDescription}`

                const result = await vm.createOutcome(content, source)
                if (result.success) {
                  vm.fetchOutcomes()

                  successToast('Skill level example created')
                } else {
                  errorToast('Something went wrong, please try again')
                }
              }}
            >
              Generate example
            </AiGenerateButton>
          </div>
        )}
      </div>
    )
  }
)
