import { Skill } from 'store/modules/skills'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'
import { can } from 'app/packs/src/policies'

export class HeaderVm {
  constructor(private skill: Skill, private skillVariant?: SkillVariant) {}

  get editableVariant() {
    return (
      store.featureGates.canAccess('skill_variants') &&
      this.skillVariant &&
      !this.skillVariant.default &&
      !!store.currentUser &&
      can(store.currentUser, this.skillVariant).edit
    )
  }

  get name() {
    return this.skillVariant?.fullName || this.skill.name
  }
}
