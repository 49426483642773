import { can } from 'app/packs/src/policies'
import {
  canCopyToClipboard,
  copyToClipboard,
} from 'app/packs/src/utils/clipboard'
import { Discipline } from 'store/modules/disciplines'
import { Position } from 'store/modules/positions'
import { SkillVariant } from 'store/modules/skill-variants'
import { Skill } from 'store/modules/skills'
import { store } from 'store/index'
import { successToast } from 'app/packs/src/utils/success-toast'
import { Team } from 'store/modules/teams'
import { Breadcrumb } from 'components/mega-modal/breadcrumb'

export class NavVm {
  constructor(
    private skill: Skill,
    private discipline?: Discipline,
    private editable?: boolean,
    private position?: Position,
    private showCloseButton?: boolean,
    private showControls?: boolean,
    private showMakeACopy?: boolean,
    private skillVariant?: SkillVariant,
    private team?: Team
  ) {}

  get breadcrumbs(): Breadcrumb[] {
    let values: Breadcrumb[]
    if (this.team) {
      values = [
        { label: 'Teams', url: this.team.org.basePath },

        { label: this.team.name, url: this.team.baseUrl },
      ]
      if (this.discipline) values.push(this.discipline.name)
      if (this.position)
        values.push({
          label: this.position.name,
          url: new URL(this.position.url(this.discipline?.id)).pathname,
        })
    } else if (
      this.skill.org?.name &&
      this.skill.org == store.currentUser?.org
    ) {
      values = [
        { label: this.skill.org.name, url: this.skill.org.basePath },
        { label: 'Skills', url: this.skill.org.skillsPath },
      ]
    } else {
      values = ['Library', { label: 'Skills', url: '/skills' }]
    }

    return [...values, this.skill.name]
  }

  get isDefaultVariant() {
    return !this.skillVariant || this.skillVariant?.default
  }

  get frameworksSkill() {
    if (!this.team?.framework || !this.skillVariant) return null
    return store.frameworksSkills.forFrameworkAndSkillVariant(
      this.team.framework.id,
      this.skillVariant.id
    )
  }

  get canCopy() {
    return (
      this.isDefaultVariant &&
      this.showMakeACopy &&
      !!store.currentUser &&
      can(store.currentUser, this.skill).copy
    )
  }

  get canCreateVariant() {
    return (
      this.isDefaultVariant &&
      store.featureGates.canAccess('skill_variants') &&
      !!store.currentUser &&
      can(store.currentUser, this.skill).edit
    )
  }

  get canDelete() {
    return !!store.currentUser && can(store.currentUser, this.skill).destroy
  }

  get canEditVariant() {
    return (
      store.featureGates.canAccess('skill_variants') &&
      this.skillVariant &&
      !this.isDefaultVariant &&
      !!store.currentUser &&
      can(store.currentUser, this.skillVariant).edit
    )
  }

  get showControlOptions() {
    if (!store.currentUser?.org) return
    return (
      this.showControls &&
      (store.currentUser.isAdmin ||
        store.currentUser.isEditorOfOrg(store.currentUser.org.id))
    )
  }

  get showAddSkillToOrg() {
    return !this.team && !this.skillInOrg && this.skill.cloneable
  }

  get showAddSkillToTeam() {
    return this.team && !this.skillInUse
  }

  get showOverflowControls() {
    if (!store.currentUser || !store.currentUser.org) return false

    return (
      this.skillInOrg &&
      (this.skillInUse || !this.team) &&
      store.currentUser.isAdminOrEditor
    )
  }

  get showCloseVariantPreview() {
    return (
      this.team?.framework &&
      this.skillVariant &&
      this.editable &&
      this.showCloseButton &&
      !this.frameworksSkill
    )
  }

  get skillVariantInTeam() {
    return (
      this.team?.framework &&
      this.skillVariant?.frameworkIds.includes(this.team.framework.id)
    )
  }

  get showVariantToggle() {
    return (
      this.skill.skillVariants.length > 1 &&
      store.featureGates.canAccess('skill_variants')
    )
  }

  get skillInUse() {
    return this.skill.teamsAndTemplates.some((t) => t.id === this.team?.id)
  }

  get skillInOrg() {
    return this.skill.org === store.currentUser?.org
  }

  get canCopyLink() {
    return canCopyToClipboard
  }

  copyLink() {
    if (copyToClipboard(this.skillVariant?.url || this.skill.url)) {
      successToast('Link copied to clipboard')
    }
  }
}
