import { errorToast } from 'app/packs/src/utils/error-toast'
import { successToast } from 'app/packs/src/utils/success-toast'
import { EditorContext } from 'components/atoms/editor'
import { store } from 'store/index'
import { AsyncTaskOutput } from 'store/modules/async-tasks'
import { SkillVariant } from 'store/modules/skill-variants'
import { Skill } from 'store/modules/skills'

export const onUseSkillContentGeneration = async (
  output: AsyncTaskOutput,
  skill: Skill,
  skillVariant: SkillVariant,
  editor: React.RefObject<EditorContext>,
  removeExistingSkillContent: boolean,
  applySkillDescription: boolean
) => {
  const extracted = output.skillContent?.output.extracted.at(0)

  if (!extracted) {
    errorToast(
      'Sorry, there was a problem applying the AI description, please try again.'
    )
    return
  }

  if (skill.defaultVariant && applySkillDescription) {
    const updatedSkill = await store.skills.update(skill.id, {
      description: extracted.skillDescription,
    })

    if (updatedSkill.success) {
      const DOC = {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: extracted.skillDescription,
              },
            ],
          },
        ],
      }
      editor.current?.reset(DOC)
    }
  }

  if (removeExistingSkillContent) {
    await skillVariant.skillLevels.map(async (level) => {
      await store.skillLevels.destroy(level.id)
    })
  }

  await extracted.levels.map(async (level) => {
    const l = {
      level: parseInt(level.skillLevelNumber),
      name: level.skillLevelDescription,
      skillVariant: skillVariant.id,
    }
    const newLevel = await store.skillLevels.create(l, {
      source: 'inline-ai-content',
    })

    if (newLevel.success && newLevel.data) {
      const levelId = Object.keys(newLevel.data.skillLevels)[0]

      level.examples.map(async (example) => {
        const content = `<b>${example.skillExampleName}</b>: ${example.skillExampleDescription}`

        const e = {
          content,
          skillLevel: levelId,
        }
        await store.outcomes.create(e, { source: 'inline-ai-content' })
      })
    }
  })

  successToast('Skill content generated ✨')

  document.dispatchEvent(new Event('skill:updated'))
}
