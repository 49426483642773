import { SkillLevel } from 'store/modules/skill-levels'

export class SkillLevelRequirementsSectionVm {
  constructor(private skillLevels: SkillLevel[]) {}

  get levels() {
    const length =
      this.skillLevels.length > 0
        ? this.skillLevels[this.skillLevels.length - 1].level
        : 1

    return Array.from({ length }, (_, i) => i + 1)
  }

  skillLevel(level: number) {
    return this.skillLevels.find((skillLevel) => skillLevel.level === level)
  }
}
