import { store } from 'store/index'
import { can } from '../../policies'
import { SkillVariant } from 'store/modules/skill-variants'

export class SkillModalContentVm {
  constructor(private skillVariant?: SkillVariant | null) {}

  get editable() {
    if (!store.currentUser) return false
    if (!this.skillVariant?.skill?.org) return false

    return can(store.currentUser, this.skillVariant.skill).edit
  }

  get headerEditable() {
    return this.editable && (!this.skillVariant || this.skillVariant?.default)
  }

  get isOrgSkill() {
    return this.skillVariant?.skill?.org === store.currentUser?.org
  }

  get showAddSkillToOrg() {
    return !this.isOrgSkill
  }
}
