import { errorToast } from 'app/packs/src/utils/error-toast'
import { successToast } from 'app/packs/src/utils/success-toast'
import { EditorContext } from 'components/atoms/editor'
import { store } from 'store/index'
import { AsyncTaskOutput } from 'store/modules/async-tasks'
import { Skill } from 'store/modules/skills'

export const onUseGeneratedSkillDescription = async (
  output: AsyncTaskOutput,
  skill: Skill,
  editor: React.RefObject<EditorContext>
) => {
  const content = output.skillDescription?.output.extracted || ''

  const updated = await store.skills.update(skill.id, {
    description: content,
  })

  if (updated.success) {
    successToast('Applied AI skill description ✨')
    const DOC = {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: content,
            },
          ],
        },
      ],
    }
    editor.current?.reset(DOC)

    document.dispatchEvent(new Event('refreshOrgSkills'))
    document.dispatchEvent(new Event('refreshSkillsDrawerSkills'))
    document.dispatchEvent(new Event('refreshTeamSkillsPage'))
  } else {
    errorToast(
      'Sorry, there was a problem applying the AI description, please try again.'
    )
  }
}
