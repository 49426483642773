import { Button, Loader } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { Trash } from '@phosphor-icons/react'
import React from 'react'
import { BlobUploadInput } from 'components/blob-upload-input'
import { errorToast } from '../../../utils/error-toast'
import { JSONAPIErrors } from '../../../api/types'
import { Skill } from 'store/modules/skills'
import { store } from 'store/index'

type SkillImageProps = {
  editable?: boolean
  skill: Skill
}

export const SkillImage = observer((props: SkillImageProps) => {
  const { editable, skill } = props

  const { skills } = store

  const [showLoader, setShowLoader] = React.useState(false)

  const deleteImage = async () => {
    setShowLoader(true)
    await skills.update(skill.id, { image: null })
    setShowLoader(false)
  }

  const afterUpload = async (signedId: string) => {
    const result = await skills.update(skill.id, { image: signedId })
    setShowLoader(false)

    if (!result.success) {
      const errors = result.errors as JSONAPIErrors['errors']
      errorToast(errors.map((e) => e.detail).join(', '))
    }
  }

  const onUploadError = (errors: JSONAPIErrors['errors']) => {
    setShowLoader(false)
    errorToast(errors.map((e) => e.detail).join(', '))
  }

  return (
    <div className="relative mb-4 group">
      <div
        className="flex justify-center items-center bg-image-cover h-40 rounded-md"
        style={{ backgroundImage: `url("${skill.imageUrl}")` }}
      />
      {editable && (
        <div className="absolute bottom-4 right-4 flex flex-row gap-x-2">
          {showLoader && <Loader />}
          <BlobUploadInput
            afterUpload={afterUpload}
            onUpload={() => setShowLoader(true)}
            onUploadError={onUploadError}
          />
          {skill.image && (
            <Button
              className="bg-white hover:!bg-gray-50 focus-within:!bg-gray-50 p-2 h-full"
              colourVariant="paper"
              onClick={deleteImage}
              variant="outline"
            >
              <Trash
                aria-hidden
                className="text-gray-600"
                size="16"
                weight="bold"
              />
              <span className="sr-only">Delete image</span>
            </Button>
          )}
        </div>
      )}
    </div>
  )
})
