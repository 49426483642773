import { Button } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { Trash } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { Editor, EditorContext } from 'components/atoms/editor'
import { ModifiableOutcomeAttributes } from 'store/modules/outcomes'
import styles from './styles.module.scss'

type NewSkillLevelOutcomeProps = {
  disabled?: boolean
  index: string
  onBlur: () => void
  onChange: (field: keyof ModifiableOutcomeAttributes, value: string) => void
  onDelete?: () => void
  onFocus: () => void
}

export const NewSkillLevelOutcome = observer(
  (props: NewSkillLevelOutcomeProps) => {
    const { disabled, index, onBlur, onChange, onDelete, onFocus } = props

    const editorRef = React.useRef<EditorContext>(null)

    return (
      <li>
        <div className="flex gap-2 items-center justify-between">
          <Editor
            className={cn(
              'border-transparent hover:border-gray-200 focus-within:!border-theme-60 focus-within:!shadow-none w-full',
              styles.nameEditor
            )}
            disabled={disabled}
            key={`editor-new-outcome-${index}`}
            name={`new-outcome-${index}-content`}
            onBlur={onBlur}
            onChange={(value) => onChange('content', value)}
            onFocus={onFocus}
            placeholder="E.g. You've repeatedly..."
            ref={editorRef}
            textSize="base"
          />
          {onDelete && (
            <Button
              className="bg-white border-0 btn-brand h-[fit-content] p-0"
              onClick={onDelete}
              type="button"
            >
              <Trash className="h-4 text-gray-300 w-4" weight="bold" />
              <span className="sr-only">Delete</span>
            </Button>
          )}
        </div>
      </li>
    )
  }
)
