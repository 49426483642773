import { Circle } from '@phosphor-icons/react'
import * as React from 'react'

type SkillLevelRequirementsEmptyStateProps = {
  editable?: boolean
  children?: React.ReactNode
}

export const SkillLevelRequirementsEmptyState: React.VFC<
  SkillLevelRequirementsEmptyStateProps
> = (props) => {
  const { editable, children } = props

  const message = editable
    ? 'Create entire high-quality skills in seconds with our AI Assistant, or write your own.'
    : 'This skill doesn’t have any skill levels.'

  return (
    <div className="flex flex-col items-center gap-4 py-6 w-full">
      <div className="flex items-center justify-center">
        <div className="bg-gray-100 h-3 mr-4 rounded w-12"></div>
        {[...Array(5)].map((_, index) => (
          <Circle className="h-4 text-gray-100 w-4" key={index} weight="fill" />
        ))}
      </div>
      <p className="mb-10 text-center text-sm text-gray-600">{message}</p>
      <div className="flex items-center gap-x-2">{children}</div>
    </div>
  )
}
