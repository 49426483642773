import { Button, Input, useModalContext } from 'src/design-system'
import { Lock } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as HoverCard from '@radix-ui/react-hover-card'
import cn from 'classnames'
import React from 'react'
import { AiGenerateButton } from 'components/ai/ai-generate-button'
import { Editor, EditorContext } from 'components/atoms/editor'
import { HeaderVm } from './header-vm'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { ModifiableSkillAttributes, Skill } from 'store/modules/skills'
import { onUseGeneratedSkillDescription } from './on-skill-description-use'
import { SKILL_MODAL_ID } from '../utils'
import { SkillImage } from './skill-image'
import { SkillVariant } from 'store/modules/skill-variants'
import { SkillVariantDialog } from 'components/skill-variant-dialog'
import styles from './styles.module.scss'

type SkillModalHeaderProps = {
  editable?: boolean
  skillDescriptionEditor: React.RefObject<EditorContext>
  errors: string[]
  formState: Partial<ModifiableSkillAttributes>
  onBlur: (
    field: keyof ModifiableSkillAttributes,
    resetOnFailure?: boolean
  ) => void
  onChange: (
    field: keyof ModifiableSkillAttributes,
    value: string | null
  ) => void
  setShowBanner: (showBanner: boolean) => void
  skill: Skill
  skillVariant?: SkillVariant
}

export const SkillModalHeader = observer((props: SkillModalHeaderProps) => {
  const {
    editable = false,
    errors,
    formState,
    onBlur,
    onChange,
    setShowBanner,
    skill,
    skillDescriptionEditor,
    skillVariant,
  } = props

  const vm = React.useMemo(() => {
    return new HeaderVm(skill, skillVariant)
  }, [skill, skillVariant])

  return (
    <div>
      <SkillImage editable={editable} skill={skill} />
      {editable && (
        <div className="-mx-2">
          <Input
            className="text-3xl font-bold py-2 pl-2 hover:border-gray-200 focus-within:!border-theme-60 focus-within:!shadow-none"
            isInvalid={errors.includes('name')}
            key={`input-${skill.id}`}
            name="name"
            naked
            onBlur={() => onBlur('name', true)}
            onChange={(e) => onChange('name', e.target.value)}
            onFocus={() => setShowBanner(true)}
            value={formState.name ?? ''}
          />
          <div className="relative group">
            <Editor
              className={cn(
                'border-transparent hover:border-gray-200 focus-within:!border-theme-60 focus-within:!shadow-none pr-12',
                styles.descriptionEditor
              )}
              initialContent={formState.description || ''}
              invalid={errors.includes('description')}
              key={`editor-${skill.id}`}
              name="description"
              onBlur={() => onBlur('description')}
              onChange={(value) => onChange('description', value)}
              onFocus={() => setShowBanner(true)}
              placeholder="Add description"
              ref={skillDescriptionEditor}
              textSize="base"
            />
            {editable && (
              <AiGenerateButton
                className="absolute right-1 bottom-1"
                contentType="skillDescription"
                inputs={{ skillName: formState.name }}
                onUse={(output) =>
                  onUseGeneratedSkillDescription(
                    output,
                    skill,
                    skillDescriptionEditor
                  )
                }
                tooltipContent="Generate a description using our AI Assistant"
                source="inline-skill-description"
              />
            )}
          </div>
        </div>
      )}
      {!editable && (
        <>
          <div className="font-bold text-3xl flex flex-row gap-x-2 items-center relative pr-6">
            {vm.editableVariant ? (
              <>
                <SkillVariantDialog
                  skillId={skill.id}
                  skillVariantId={skillVariant?.id}
                  source="skill-modal-header"
                >
                  <span>{vm.name}</span>
                </SkillVariantDialog>
                <div className="absolute right-0 top-0">
                  <LockTooltip skill={skill} />
                </div>
              </>
            ) : (
              vm.name
            )}
          </div>
          {skill.description && (
            <HtmlContent className={cn('mt-4', styles.description)}>
              {skill.description}
            </HtmlContent>
          )}
        </>
      )}
    </div>
  )
})

const LockTooltip = (props: { skill: Skill }) => {
  const { skill } = props

  const { openModal } = useModalContext()

  const onClickMainVariant = () => {
    openModal(SKILL_MODAL_ID, { skillId: skill.id })
  }

  return (
    <HoverCard.Root openDelay={100}>
      <HoverCard.Trigger data-testid="lock-icon">
        <Lock className="h-4 text-gray-600 w-4" weight="bold" />
      </HoverCard.Trigger>
      <HoverCard.Content className="py-1 px-2 text-xs rounded bg-gray-900 text-white max-w-[200px] text-center font-normal">
        <div>
          <span>
            Skill name and description can only be changed from the Main skill
            variant.
          </span>
          <Button
            className="underline focus:shadow-none bg-transparent min-w-full text-xs p-0 mb-0.5"
            onClick={onClickMainVariant}
          >
            Go to Main variant
          </Button>
        </div>
        <HoverCard.Arrow className="fill-black" />
      </HoverCard.Content>
    </HoverCard.Root>
  )
}
