import { Button, Modal, useModalContext } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Team } from 'store/modules/teams'
import { SkillVariant } from 'store/modules/skill-variants'

export type SkillVariantClosePreviewDialogProps = {
  children: React.ReactElement
  team: Team
  skillVariant: SkillVariant
}

export const SkillVariantClosePreviewDialog =
  observer<SkillVariantClosePreviewDialogProps>((props) => {
    const { team, skillVariant, children } = props

    const { closeModal } = useModalContext()

    const [open, setOpen] = React.useState(false)

    const onSubmit = React.useCallback(async () => {
      closeModal()
    }, [closeModal])

    return (
      <Modal.Root
        className="w-[508px]"
        onOpenChange={(open) => {
          setOpen(open)
        }}
        open={open}
        title={skillVariant.skill.name}
        trigger={children}
      >
        <form
          onSubmit={onSubmit}
          className="flex flex-col w-full justify-between p-6 gap-4"
        >
          <div className="flex flex-col gap-3">
            <div className="flex flex-row items-end gap-x-2 font-bold text-lg">
              Close without swapping variant?
            </div>
            <div className="flex flex-row text-sm gap-2 items-center text-gray-600">
              The selected variant has not been added to the {team.name}{' '}
              framework. Click ‘Swap into team’ if you want to use this variant
              in {team.name}.
            </div>
          </div>
          <div className="flex flex-row gap-2 justify-end">
            <Button
              variant="outline"
              onClick={() => {
                setOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Yes, close without swapping</Button>
          </div>
        </form>
      </Modal.Root>
    )
  })
