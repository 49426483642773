import { exceptions } from '../../utils/exceptions'
import { JSONAPIErrors } from '../../api/types'

export class BlobUploadInputVm {
  constructor(
    private afterUpload: (blobId: string) => void,
    private onUpload?: () => void,
    private onUploadError?: (errors: JSONAPIErrors['errors']) => void
  ) {}

  async onFileSelected(
    base64String: string,
    filename: string,
    contentType: string
  ) {
    this.onUpload?.()

    const body = {
      data: {
        type: 'blobs',
        attributes: {
          data: base64String,
          filename: filename,
          content_type: contentType,
        },
      },
    }

    try {
      const response = await fetch('/api/v1/blobs', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
      })
      const json = await response.json()
      if (json.errors) {
        this.onUploadError?.(json.errors)
      } else {
        const blobId = json.data?.id
        if (blobId) this.afterUpload(blobId)
      }
    } catch (e) {
      const error = e as Error
      exceptions.handle(error)
    }
  }
}
