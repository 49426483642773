import {
  ArrowsOut,
  Copy,
  GitBranch,
  LinkSimple,
  MinusCircle,
  TextT,
  Trash,
  X,
} from '@phosphor-icons/react'
import {
  Button,
  GlobalConfirmationDialog,
  OverflowMenu,
  Tag,
  Tooltip,
  useConfirmationDialogContext,
  useModalContext,
} from 'src/design-system'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Discipline } from 'store/modules/disciplines'
import { errorToast } from '../../../utils/error-toast'
import { NavVm } from './nav-vm'
import { Position } from 'store/modules/positions'
import { RequestResult } from 'app/packs/src/api/types'
import { Skill } from 'store/modules/skills'
import { SkillVariant } from 'store/modules/skill-variants'
import { SkillVariantClosePreviewDialog } from '../skill-variant-close-preview-dialog'
import { SkillVariantDialog } from 'components/skill-variant-dialog'
import { SkillVariantToggle } from './skill-variant-toggle'
import { store } from 'store/index'
import { successToast } from '../../../utils/success-toast'
import { Team } from 'store/modules/teams'
import { SKILL_COPY_DIALOG_ID } from 'components/skill-copy-dialog'
import { SKILL_MODAL_ID } from '../utils'
import { Breadcrumb, CloseButton, RightPaneButton } from 'components/mega-modal'

export type SkillModalNavProps = {
  discipline?: Discipline
  editable?: boolean
  frameworkId?: string
  position?: Position
  showCloseButton?: boolean
  showControls?: boolean
  showMakeACopy?: boolean
  showRemoveFromTeam?: boolean
  skill: Skill
  skillVariant?: SkillVariant
  team?: Team
}

export const SkillModalNav = observer((props: SkillModalNavProps) => {
  const {
    discipline,
    editable,
    position,
    showCloseButton = false,
    showControls = true,
    showMakeACopy = true,
    showRemoveFromTeam = true,
    skill,
    skillVariant = skill.defaultVariant,
    team,
  } = props

  const { currentUser, frameworksSkills, skills } = store

  const { closeModal, openModal } = useModalContext()
  const { openConfirmationDialog } = useConfirmationDialogContext()

  const source = 'skill-modal'

  const vm = React.useMemo(() => {
    return new NavVm(
      skill,
      discipline,
      editable,
      position,
      showCloseButton,
      showControls,
      showMakeACopy,
      skillVariant,
      team
    )
  }, [
    skill,
    discipline,
    editable,
    position,
    showCloseButton,
    showControls,
    showMakeACopy,
    skillVariant,
    team,
  ])

  const dispatchEvents = React.useCallback(() => {
    document.dispatchEvent(new Event('frameworkpage:updated'))
    document.dispatchEvent(new Event('refreshOrgSkills'))
    document.dispatchEvent(new Event('refreshSkillsDrawerSkills'))
    document.dispatchEvent(new Event('refreshTeamSkillsPage'))
  }, [])

  const objectToDelete = vm.isDefaultVariant ? 'Skill' : 'Skill Variant'

  const onDelete = React.useCallback(async () => {
    let result: RequestResult

    closeModal()

    if (!skillVariant || skillVariant.default) {
      result = await skill.destroy({ source })
    } else {
      result = await skillVariant.destroy()
    }

    if (result.success) {
      successToast(`${objectToDelete} deleted`)
      dispatchEvents()
    } else {
      errorToast()
    }
  }, [closeModal, dispatchEvents, objectToDelete, skill, skillVariant])

  const onMakeACopy = React.useCallback(async () => {
    if (!currentUser?.org) return

    const result = await skills.create({ clonedFrom: skill.id }, { source })
    if (result.success) {
      successToast('Skill copied')
      closeModal()

      openModal(SKILL_MODAL_ID, {
        skillId: Object.keys(result.data?.skills || {})[0],
        source,
        frameworkId: team?.framework?.id,
      })
    } else {
      errorToast()
    }
  }, [
    closeModal,
    currentUser?.org,
    openModal,
    skill.id,
    skills,
    team?.framework?.id,
  ])

  const onAddToTeam = React.useCallback(async () => {
    if (!team?.framework || !skillVariant) return

    const result = await frameworksSkills.create(
      {
        framework: team.framework.id,
        skillVariant: skillVariant.id,
      },
      { source, include: ['skill'] }
    )

    if (result.success) {
      successToast('Skill added to framework')
      dispatchEvents()
      closeModal()
    } else {
      errorToast()
    }
  }, [
    closeModal,
    dispatchEvents,
    frameworksSkills,
    skillVariant,
    team?.framework,
  ])

  const onRemoveSkillFromTeam = React.useCallback(async () => {
    if (!vm.frameworksSkill) return

    const result = await vm.frameworksSkill.destroy({ source })

    if (result.success) {
      successToast('Skill removed')
      dispatchEvents()
      closeModal()
    } else {
      errorToast()
    }
  }, [closeModal, dispatchEvents, vm.frameworksSkill])

  if (!currentUser || !skill) return null

  return (
    <div className="flex flex-row justify-between items-center w-full">
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row items-center">
          <div className="flex gap-1 items-center">
            <div className="hidden md:flex md:items-center">
              <Breadcrumb breadcrumbs={vm.breadcrumbs} />
            </div>
            {vm.showVariantToggle && (
              <>
                <span className="mx-1 text-gray-200">/</span>
                <div className="rounded border border-gray-600 px-2 py-1">
                  <SkillVariantToggle
                    skill={skill}
                    skillVariant={skillVariant}
                    framework={team?.framework || undefined}
                  />
                </div>
                <Tag variant="paper">+{skill.skillVariants.length - 1}</Tag>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-4 items-center">
        {vm.showControlOptions && (
          <>
            {vm.canCopyLink && (
              <Tooltip content="Copy link">
                <button
                  className="text-gray-300 hover:text-theme-40 leading-none"
                  onClick={() => vm.copyLink()}
                >
                  <LinkSimple weight="bold" className="w-4 h-4" />
                </button>
              </Tooltip>
            )}
            {showCloseButton && (
              <Tooltip content="Open as Page">
                <a
                  className="text-gray-300 hover:text-theme-40 leading-none"
                  href={skillVariant?.url || skill.url}
                >
                  <ArrowsOut weight="bold" className="w-4 h-4" />
                </a>
              </Tooltip>
            )}
            <RightPaneButton />
            {vm.showOverflowControls && (
              <OverflowMenu.Root contentClassName="min-w-[160px]" right>
                {vm.canCopyLink && (
                  <OverflowMenu.Button onClick={() => vm.copyLink()}>
                    <div className="flex gap-2 items-center text-gray-900">
                      <LinkSimple
                        aria-hidden
                        className="w-4 h-4"
                        weight="bold"
                      />
                      Copy link
                    </div>
                  </OverflowMenu.Button>
                )}
                {vm.canCopy &&
                store.featureGates.canAccess('skill_variants') ? (
                  <Button
                    className="bg-transparent flex focus:shadow-none gap-2 hover:!bg-gray-50 items-center justify-start min-w-full px-3 py-2.5 rounded-none text-gray-900"
                    onClick={() =>
                      openModal(SKILL_COPY_DIALOG_ID, {
                        onMakeACopy,
                        skill,
                        source,
                      })
                    }
                  >
                    <Copy aria-hidden className="h-4 w-4" weight="bold" />
                    Make a copy
                  </Button>
                ) : (
                  vm.canCopy && (
                    <OverflowMenu.Item onClick={onMakeACopy}>
                      <div className="flex gap-2 items-center text-gray-900">
                        <Copy aria-hidden className="h-4 w-4" weight="bold" />
                        Make a copy
                      </div>
                    </OverflowMenu.Item>
                  )
                )}
                {vm.canCreateVariant && (
                  <SkillVariantDialog
                    frameworkId={team?.framework?.id}
                    skillId={skill.id}
                    source={source}
                  >
                    <Button className="bg-transparent flex focus:shadow-none gap-2 hover:!bg-gray-50 items-center justify-start min-w-full px-3 py-2.5 rounded-none text-gray-900">
                      <GitBranch
                        aria-hidden
                        className="h-4 w-4"
                        weight="bold"
                      />
                      Create variant...
                    </Button>
                  </SkillVariantDialog>
                )}
                {vm.skillVariantInTeam && showRemoveFromTeam && (
                  <OverflowMenu.Item
                    onClick={() => {
                      openConfirmationDialog('skill-modal', {
                        title: `Are you sure you want to remove this skill?`,
                        body: `This will remove ${skill.name} from the team and all positions in the team. Usage in other teams will not be affected.`,
                        onConfirm: async () => {
                          onRemoveSkillFromTeam()
                        },
                      })
                    }}
                  >
                    <div className="flex gap-2 items-center text-gray-900">
                      <MinusCircle
                        aria-hidden
                        className="h-4 w-4"
                        weight="bold"
                      />
                      Remove from team
                    </div>
                  </OverflowMenu.Item>
                )}
                {vm.canEditVariant && (
                  <SkillVariantDialog
                    skillId={skill.id}
                    skillVariantId={skillVariant?.id}
                    source={source}
                  >
                    <Button className="bg-transparent flex focus:shadow-none gap-2 hover:!bg-gray-50 items-center justify-start min-w-full px-3 py-2.5 rounded-none text-gray-900">
                      <TextT aria-hidden className="h-4 w-4" weight="bold" />
                      Rename variant
                    </Button>
                  </SkillVariantDialog>
                )}
                {vm.canDelete && (
                  <OverflowMenu.Item
                    onClick={() => {
                      openConfirmationDialog('skill-modal', {
                        title: `Are you sure you want to delete this ${objectToDelete}?`,
                        body: `This will permanently remove this ${objectToDelete} from Progression.`,
                        onConfirm: async () => {
                          onDelete()
                        },
                      })
                    }}
                    variant="destructive"
                  >
                    <div className="flex gap-2 items-center">
                      <Trash aria-hidden className="h-4 w-4" weight="bold" />
                      {vm.isDefaultVariant ? 'Delete' : 'Delete variant'}
                    </div>
                  </OverflowMenu.Item>
                )}
              </OverflowMenu.Root>
            )}
            {vm.showAddSkillToTeam && (
              <>
                <div className="hidden md:block">
                  <Button variant="outline" onClick={onAddToTeam}>
                    Add skill to team
                  </Button>
                </div>
                <OverflowMenu.Root
                  className="md:hidden"
                  contentClassName="min-w-[160px]"
                  right
                >
                  <OverflowMenu.Item onClick={onAddToTeam}>
                    Add skill to team
                  </OverflowMenu.Item>
                </OverflowMenu.Root>
              </>
            )}
            {vm.showAddSkillToOrg && (
              <>
                <div className="hidden md:block">
                  <Button variant="outline" onClick={onMakeACopy}>
                    Add skill to org
                  </Button>
                </div>
                <OverflowMenu.Root
                  className="md:hidden"
                  contentClassName="min-w-[160px]"
                  right
                >
                  <OverflowMenu.Item onClick={onMakeACopy}>
                    Add skill to org
                  </OverflowMenu.Item>
                </OverflowMenu.Root>
              </>
            )}
          </>
        )}
        {vm.showCloseVariantPreview && team && skillVariant ? (
          <SkillVariantClosePreviewDialog
            team={team}
            skillVariant={skillVariant}
          >
            <X weight="bold" className="text-gray-900 w-4 h-4" />
          </SkillVariantClosePreviewDialog>
        ) : (
          showCloseButton && <CloseButton />
        )}
      </div>
      <GlobalConfirmationDialog id="skill-modal" />
    </div>
  )
})
