import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { EditableByInfoLine } from './editable-by-info-line'
import { LicenseInfoLine } from './license-info-line'
import { Link } from 'src/design-system'
import { OrgInfoLine } from './org-info-line'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'
import { UsedByInfoLine } from './used-by-info-line'
import { TabbedInfoSection } from 'components/tabbed-info-section'

type InfoPaneProps = {
  editable: boolean
  skillVariant: SkillVariant
  canAddToOrg?: boolean
}

export const InfoPane = observer((props: InfoPaneProps) => {
  const { editable, skillVariant, canAddToOrg } = props

  const skill = skillVariant.skill

  if (!skill) return null

  const editableInfoLine = editable && (!skillVariant || skillVariant.default)

  return (
    <div className="flex flex-col gap-8">
      {canAddToOrg && (
        <>
          {skill.org && <OrgInfoLine org={skill.org} />}
          {skill.contentLicenseGrant && (
            <LicenseInfoLine license={skill.contentLicenseGrant} />
          )}
        </>
      )}
      {!canAddToOrg && (
        <>
          {skillVariant.teams.length > 0 && (
            <UsedByInfoLine teams={skillVariant.teams} />
          )}
          <EditableByInfoLine skill={skill} editable={editableInfoLine} />
        </>
      )}
      {editable && <TabbedInfoSection resource={skillVariant} />}
    </div>
  )
})
