import { Button, Tag } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { Plus } from '@phosphor-icons/react'
import * as React from 'react'
import { AiGenerateButton } from 'components/ai/ai-generate-button'
import { EditorContext } from 'components/atoms/editor'
import { NewSkillLevelRequirementCard } from './new-skill-level-requirement-card'
import { Skill } from 'store/modules/skills'
import { SkillLevel } from 'store/modules/skill-levels'
import { SkillLevelRequirementCard } from './skill-level-requirement-card'
import { SkillLevelRequirementsEmptyState } from './skill-level-requirements-empty-state'
import { SkillLevelRequirementsSectionVm } from './skill-level-requirements-section-vm'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'
import { Team } from 'store/modules/teams'
import { onUseSkillContentGeneration } from './on-use-skill-generation-content'

type SkillLevelRequirementsSectionProps = {
  deletable?: boolean
  editable?: boolean
  skillDescriptionEditor: React.RefObject<EditorContext>
  frameworkCount: number
  setShowBanner: (showBanner: boolean) => void
  skill: Skill
  skillLevels: SkillLevel[]
  skillVariant: SkillVariant
  team?: Team
}

export const SkillLevelRequirementsSection = observer(
  (props: SkillLevelRequirementsSectionProps) => {
    const {
      deletable,
      editable,
      skillDescriptionEditor,
      frameworkCount,
      setShowBanner,
      skill,
      skillLevels,
      skillVariant,
      team,
    } = props

    const vm = React.useMemo(() => {
      return new SkillLevelRequirementsSectionVm(skillLevels)
    }, [skillLevels])

    const [levels, setLevels] = React.useState(vm.levels)

    const [editing, setEditing] = React.useState(
      editable && skillLevels.length > 0
    )

    React.useEffect(() => {
      setEditing(editable && skillLevels.length > 0)
    }, [editable, skillLevels.length, skillVariant.default])

    React.useEffect(() => {
      setLevels(vm.levels)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skillLevels.length])

    const showLevels = skillLevels.length > 0 || editing

    const skillHasContent = skillLevels.length > 0 || skill.description

    return (
      <div className="group">
        <div className="flex items-center justify-between">
          <div className="flex gap-2 items-center">
            <h5 className="font-bold text-base text-gray-600">Levels</h5>
            <Tag variant="paper">{skillLevels.length}</Tag>
          </div>
          {editing && (
            <AiGenerateButton
              className="text-pink-600 border-pink-600"
              source="inline-skill-content-always-on"
              inputs={{
                teamType: team?.teamType,
                skillName: skill.name,
                showWarning: editing && skillHasContent,
              }}
              contentType="skillContent"
              onUse={(output) =>
                onUseSkillContentGeneration(
                  output,
                  skill,
                  skillVariant,
                  skillDescriptionEditor,
                  Boolean(skillHasContent),
                  false
                )
              }
              enablePreview={false}
            >
              {skillLevels.length === 0
                ? 'Generate all levels'
                : 'Regenerate all levels'}
            </AiGenerateButton>
          )}
        </div>
        {showLevels &&
          levels.map((level) => (
            <SkillLevelCard
              deletable={deletable && skillVariant.default}
              editable={editable}
              frameworkCount={frameworkCount}
              key={level}
              level={level}
              maxLevel={levels[levels.length - 1]}
              setShowBanner={setShowBanner}
              skillLevel={vm.skillLevel(level)}
              skillVariant={skillVariant}
            />
          ))}
        {skillLevels.length === 0 && !editing && (
          <SkillLevelRequirementsEmptyState editable={editable}>
            {editable && (
              <>
                <AiGenerateButton
                  className="text-pink-600 border-pink-600"
                  source="inline-skill-content-empty-state"
                  inputs={{
                    teamType: team?.teamType,
                    skillName: skill.name,
                    showWarning: false,
                  }}
                  contentType="skillContent"
                  onUse={(output) =>
                    onUseSkillContentGeneration(
                      output,
                      skill,
                      skillVariant,
                      skillDescriptionEditor,
                      false,
                      false
                    )
                  }
                  enablePreview={false}
                >
                  Generate all levels
                </AiGenerateButton>
                <Button variant="outline" onClick={() => setEditing(true)}>
                  + Add level
                </Button>
              </>
            )}
          </SkillLevelRequirementsEmptyState>
        )}
        {editing && (
          <div className="flex flex-row items-center gap-x-2 justify-center mb-12 mt-6 w-full">
            <Button
              as="button"
              className="flex items-center px-3 py-1.5 text-theme-40"
              onClick={() =>
                setLevels((levels) => [...levels, levels.length + 1])
              }
              variant="outline"
            >
              <Plus className="h-4 w-4" weight="bold" />
              <span className="text-sm"> Add level</span>
            </Button>
            {skillVariant.default && (
              <AiGenerateButton
                source="inline-skill-level-description"
                popoverContentProps={{
                  side: 'top',
                  sideOffset: 8,
                }}
                inputs={{
                  skillName: skill.name,
                  existingSkillLevelCount: skillVariant.skillLevels.length + 1,
                  skillLevelDescriptions: skillVariant.skillLevels.map(
                    (skillLevel) => {
                      if (skillLevel.name) {
                        return `Level ${skillLevel.level}: ${skillLevel.name}`
                      }
                    }
                  ),
                }}
                contentType="skillLevelDescription"
                onUse={async (output) => {
                  store.skillLevels.create(
                    {
                      level: levels.length + 1,
                      name:
                        output.skillLevelDescription?.output?.extracted || '',
                      skillVariant: skillVariant.id,
                    },
                    { source: 'inline-ai-skill-level-description' }
                  )
                }}
              >
                Generate level
              </AiGenerateButton>
            )}
          </div>
        )}
      </div>
    )
  }
)

type SkillLevelCardProps = {
  deletable?: boolean
  editable?: boolean
  frameworkCount: number
  level: number
  maxLevel: number
  setShowBanner: (showBanner: boolean) => void
  skillLevel?: SkillLevel
  skillVariant: SkillVariant
}

const SkillLevelCard = observer((props: SkillLevelCardProps) => {
  const {
    deletable,
    editable,
    frameworkCount,
    level,
    maxLevel,
    setShowBanner,
    skillLevel,
    skillVariant,
  } = props

  if (skillLevel) {
    return (
      <SkillLevelRequirementCard
        deletableLevel={deletable}
        deletableOutcome={deletable}
        editable={editable}
        frameworkCount={frameworkCount}
        maxLevel={maxLevel}
        outcomes={skillLevel.outcomes}
        setShowBanner={setShowBanner}
        skillLevel={skillLevel}
        skillVariant={skillVariant}
        source="skill-modal"
      />
    )
  }

  if (!editable) return null

  return (
    <NewSkillLevelRequirementCard
      level={level}
      maxLevel={maxLevel}
      setShowBanner={setShowBanner}
      skillVariant={skillVariant}
    />
  )
})
