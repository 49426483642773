import { Button } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { Plus } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { Editor, EditorContext } from 'components/atoms/editor'
import { ModifiableOutcomeAttributes } from 'store/modules/outcomes'
import { ModifiableSkillLevelAttributes } from 'store/modules/skill-levels'
import { NewSkillLevelOutcome } from './new-skill-level-outcome'
import { SkillLevelIndicator } from 'components/skill-level-indicator'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'
import { successToast } from 'app/packs/src/utils/success-toast'
import styles from './styles.module.scss'

type NewSkillLevelRequirementCardProps = {
  level: number
  maxLevel: number
  setShowBanner: (showBanner: boolean) => void
  skillVariant: SkillVariant
}

export const NewSkillLevelRequirementCard = observer(
  (props: NewSkillLevelRequirementCardProps) => {
    const { level, maxLevel, setShowBanner, skillVariant } = props

    const editorRef = React.useRef<EditorContext>(null)

    const [disabled, setDisabled] = React.useState(false)

    const [formState, setFormState] =
      React.useState<ModifiableSkillLevelAttributes>({
        level,
        name: '',
        skillVariant: skillVariant.id,
      })

    const [noOfOutcomes, setNoOfOutcomes] = React.useState(1)

    const [outcomeFormState, setOutcomeFormState] =
      React.useState<ModifiableOutcomeAttributes>({
        content: '',
        skillLevel: '',
      })

    const createSkillLevel = () => {
      setDisabled(true)

      return store.skillLevels.create(formState, {
        include: [
          'skill',
          'skill.skill_variants',
          'skill.skill_variants.skill_levels',
        ],
        source: 'skill-modal',
      })
    }

    const onBlur = async (field: keyof typeof formState) => {
      setShowBanner(false)

      if (formState[field] === '') return

      const result = await createSkillLevel()
      if (result.success) {
        successToast('Skill level created')
        document.dispatchEvent(new Event('frameworkpage:updated'))
        store.changes.fetchForResource(skillVariant.id, 'SkillVariant', 1)
      }
    }

    const onChange = <Field extends keyof ModifiableSkillLevelAttributes>(
      field: Field,
      value: ModifiableSkillLevelAttributes[Field] | null
    ) => {
      setFormState((formState) => ({ ...formState, [field]: value }))
    }

    const onOutcomeBlur = async () => {
      setShowBanner(false)

      if (outcomeFormState['content'] === '') return

      const skillLevelResult = await createSkillLevel()
      if (skillLevelResult.success && skillLevelResult.data) {
        const skillLevelId = Object.keys(skillLevelResult.data.skillLevels)[0]
        const newFormState = { ...outcomeFormState, skillLevel: skillLevelId }
        const outcomeResult = await store.outcomes.create(newFormState)

        if (outcomeResult.success) {
          successToast('Skill level example created')
          document.dispatchEvent(new Event('frameworkpage:updated'))
          store.changes.fetchForResource(skillVariant.id, 'SkillVariant', 2)
        }
      }
    }

    const onOutcomeChange = <Field extends keyof ModifiableOutcomeAttributes>(
      field: Field,
      value: ModifiableOutcomeAttributes[Field] | null
    ) => {
      setOutcomeFormState((outcomeFormState) => ({
        ...outcomeFormState,
        [field]: value,
      }))
    }

    return (
      <div className="border-0 border-b border-gray-100 border-solid flex flex-col gap-4 py-6">
        <div className="flex gap-2 items-center justify-between">
          <h4 className="font-bold text-gray-900 text-lg truncate">
            Level {level}
          </h4>
          <SkillLevelIndicator level={level} maxLevel={maxLevel} />
        </div>
        <Editor
          className={cn(
            'border-transparent hover:border-gray-200 focus-within:!border-theme-60 focus-within:!shadow-none -mx-2',
            styles.nameEditor
          )}
          disabled={disabled}
          key={`editor-new-skill-level-${level}`}
          name="name"
          onBlur={() => onBlur('name')}
          onChange={(value) => onChange('name', value)}
          onFocus={() => setShowBanner(true)}
          placeholder="E.g. Recognised as leading industry figure and uses baking to achieve organisational goals."
          ref={editorRef}
          textSize="base"
        />
        <h5 className="font-bold text-base text-gray-900">Examples</h5>
        <ul className={cn(styles.list)}>
          {[...Array(noOfOutcomes)].map((_, index) => (
            <NewSkillLevelOutcome
              disabled={disabled}
              index={index.toString()}
              key={index}
              onBlur={onOutcomeBlur}
              onChange={onOutcomeChange}
              onFocus={() => setShowBanner(true)}
            />
          ))}
        </ul>
        <Button
          as="button"
          className="border-gray-100 flex items-center px-3 py-1.5"
          disabled={disabled}
          onClick={() => setNoOfOutcomes((noOfOutcomes) => noOfOutcomes + 1)}
          variant="outline"
        >
          <Plus className="h-4 text-theme-40 w-4" weight="bold" />
          <span className="text-gray-600 text-sm"> Add example</span>
        </Button>
      </div>
    )
  }
)
