import { PencilSimple } from '@phosphor-icons/react'
import React from 'react'
import { JSONAPIErrors } from '../../api/types'
import { BlobUploadInputVm } from './blob-upload-input-vm'

type BlobUploadInputProps = {
  afterUpload: (signedId: string) => void
  onUpload?: () => void
  onUploadError?: (errors: JSONAPIErrors['errors']) => void
}

export const BlobUploadInput: React.VFC<BlobUploadInputProps> = (props) => {
  const { afterUpload, onUpload, onUploadError } = props

  const vm = new BlobUploadInputVm(afterUpload, onUpload, onUploadError)

  const handleFileInputChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (event) => {
    event.preventDefault()

    const selectedFile = event.target.files?.[0]
    if (!selectedFile) return

    const reader = new FileReader()

    reader.onloadend = async () => {
      const base64String = reader.result
        ?.toString()
        .replace('data:', '')
        .replace(/^.+,/, '')

      if (!base64String) return

      await vm.onFileSelected(
        base64String,
        selectedFile.name,
        selectedFile.type
      )
    }

    reader.readAsDataURL(selectedFile)
  }

  return (
    <label className="outline-none rounded bg-white hover:!bg-gray-50 focus-within:!bg-gray-50 transition-visible duration-200 ease-in-out p-2 flex justify-center border border-gray-100 items-center mb-0 cursor-pointer opacity-0 group-hover:opacity-100">
      <PencilSimple
        aria-hidden
        weight="bold"
        size="16"
        className="text-gray-600"
      />
      <input className="hidden" type="file" onChange={handleFileInputChange} />
      <span className="sr-only">Upload image</span>
    </label>
  )
}
