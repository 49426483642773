import {
  Banner,
  BannerProps,
  Button,
  PropsWithoutChildren,
  useTimedContent,
} from 'src/design-system'
import { Info } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { SkillVariant } from 'store/modules/skill-variants'
import { successToast } from 'app/packs/src/utils/success-toast'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { useStore } from 'store/context'

export type SkillVariantPreviewBannerProps = {
  frameworkId: string
  skillVariant: SkillVariant
  editable: boolean
} & PropsWithoutChildren<Omit<BannerProps, 'onDismiss' | 'variant'>>

export const SkillVariantPreviewBanner = observer(
  (props: SkillVariantPreviewBannerProps) => {
    const { frameworkId, skillVariant, editable, ...restProps } = props
    const { frameworksSkills } = useStore()

    const [hideId, setHideId] = React.useState<string | null>(null)
    const { hideFor, show } = useTimedContent(
      `skillModalSkillVariantPreviewBanner-${skillVariant.id}`
    )

    const onDismiss = () => {
      hideFor({ hours: 24 })
      trackEvent('$track_skill_modal_variant_preview_banner_dismissed')
    }

    const frameworkSkill = frameworksSkills.forFrameworkAndSkill(
      frameworkId,
      skillVariant.skill.id
    )

    const onSwapVariant = React.useCallback(async () => {
      const result = await frameworksSkills.create(
        {
          framework: frameworkId,
          skillVariant: skillVariant.id,
        },
        { include: ['skill'] }
      )

      if (result.success) {
        successToast('Skill variant swapped')
        trackEvent(
          '$track_skill_modal_variant_preview_banner_swap_variant_clicked'
        )
        document.dispatchEvent(new Event('frameworkpage:updated'))
        document.dispatchEvent(new Event('skill:updated'))
        setHideId(skillVariant.id)
      } else {
        errorToast()
      }
    }, [frameworkId, frameworksSkills, skillVariant.id])

    if (
      !show ||
      !editable ||
      !frameworkSkill ||
      frameworkSkill?.skillVariant === skillVariant ||
      hideId === skillVariant.id
    )
      return null

    return (
      <Banner
        className="bg-orange-100 absolute bottom-6 flex items-center left-0 mx-auto my-0 right-0 rounded w-max z-20"
        onDismiss={onDismiss}
        variant="warning"
        {...restProps}
      >
        <div className="flex flex-row gap-3 items-center mr-3">
          <Info className="h-4 text-orange-700 w-4" weight="bold" />
          <div className="flex flex-row items-center justify-between gap-3 mb-0 text-sm text-orange-800">
            You are previewing the {skillVariant.name} variant of{' '}
            {skillVariant.skill.name}.
            <div className="flex flex-row items-center gap-3">
              <a
                className="text-orange-800 underline ml-2"
                href="https://help.progressionapp.com/en/articles/8065002-how-to-variant-skills"
              >
                Learn more
              </a>
              <Button onClick={onSwapVariant}>Swap into team</Button>
            </div>
          </div>
        </div>
      </Banner>
    )
  }
)
